<template>
  <div
    v-click-outside="close"
    :class="{ 'disabled pointer-events-none opacity-70': disabled }"
  >
    <select
      :name="selectName"
      :id="id"
      :multiple="multipleSelect"
      class="w-px absolute"
      style="clip: rect(0 0 0 0) !important; clip-path: inset(50%) !important"
      @focus="startSearch"
    >
      <option value="" :selected="blankOptionSelected"></option>

      <option
        v-if="singleOptionSelected"
        :value="selectedOption.value"
        selected
      ></option>

      <option
        v-for="option in selectedMultipleOptions"
        :value="option.value"
        :key="option.value"
        selected
      ></option>
    </select>

    <div class="h-full">
      <div
        ref="buttonstart"
        @click.prevent="startSearch"
        @focus="startSearch"
        tabindex="0"
        class="fancy-select"
        :class="{
          'hover:ring-red focus:ring-red border-red-300 text-red-900 placeholder-red-300 hover:border-red-500 hover:outline-none focus:border-red-500 focus:outline-none':
            showErrors,
          'focus:ring-blue hover:outline-none hover:ring-blue-300 focus:border-blue-300 focus:outline-none':
            !showErrors,
        }"
      >
        <template v-if="optionsAreSelected">
          <template v-if="singleOptionSelected">
            <div class="fancy-select-selection overflow-hidden">
              <img
                v-if="selectedOption.photo"
                :src="selectedOption.photo"
                class="h-5 w-5 shrink-0 rounded-full"
              />

              <span
                class="block truncate"
                :class="{ 'text-xl font-bold': bigDropdown }"
              >
                {{ selectedOption.label }}
              </span>

              <span
                v-if="selectedOption.sublabel"
                class="truncate text-gray-500"
                :class="{ 'text-md': bigDropdown }"
              >
                {{ selectedOption.sublabel }}
              </span>

              <span
                v-if="showClearButton"
                @click.stop="clearAndClose"
                class="absolute right-9 cursor-pointer text-gray-400 hover:text-gray-500"
              >
                <icon name="x-mark" class="h-4 w-4 text-navy-600" />
              </span>
            </div>
          </template>

          <template v-else>
            <!-- Multi selected -->
            <div class="flex flex-wrap items-center justify-start text-left">
              <div
                class="my-1 mr-1 flex items-center overflow-hidden whitespace-nowrap rounded-full bg-gray-100 px-2.5 py-1.5 text-xs font-medium text-gray-800"
                v-for="option in selectedMultipleOptions"
                :key="option.value"
              >
                {{ option.label }}

                <span class="ml-1 truncate" v-if="option.sublabel">
                  - {{ option.sublabel }}
                </span>

                <span
                  @click.stop="deselect(option)"
                  class="ml-1 cursor-pointer text-navy-800"
                >
                  <icon name="x-mark" class="h-4 w-4" />
                </span>
              </div>
            </div>
          </template>
        </template>

        <template v-else>
          <div class="fancy-select-placeholder">
            {{ computedPlaceholder }}
          </div>
        </template>

        <template v-if="showErrors">
          <icon
            name="exclamation-circle"
            :solid="true"
            class="h-9 w-9 py-2 text-red-500"
          />
        </template>
      </div>

      <template v-if="showErrors">
        <div
          v-for="error in errors"
          :key="error"
          class="mt-2 text-sm text-red-600"
        >
          {{ error }}
        </div>
      </template>

      <!-- Search Results -->
      <div ref="popper" class="z-10">
        <div class="mt-2 w-full rounded-md shadow-lg" v-if="searching">
          <div class="rounded-md bg-white ring-1 ring-black ring-opacity-5">
            <input
              ref="input"
              type="text"
              v-model.trim="searchValue"
              class="form-input w-full"
              @input="handleInput"
              @keydown.esc.stop="close"
              @keydown.up="highlightPrev"
              @keydown.down="highlightNext"
              @keydown.enter.prevent="selectHighlighted"
              @keydown.tab="close"
            />

            <ul
              v-if="filteredOptions.length > 0"
              ref="optionsul"
              class="overflow-y-auto py-1"
              :class="{ 'max-h-64': !bigDropdown, 'max-h-128': bigDropdown }"
            >
              <li
                @click.stop.prevent="handleClick(option)"
                @mouseover="highlight(i)"
                v-for="(option, i) in filteredOptions"
                :key="option.value"
                class="cursor-pointer"
              >
                <fancy-select-option
                  :option="option"
                  :is-highlighted="i === highlightedIndex"
                  :is-selected="isOptionSelected(option)"
                >
                </fancy-select-option>
              </li>
            </ul>

            <div
              v-if="showNoResults"
              class="h-full w-full px-4 py-2 text-left text-sm leading-5 text-gray-500"
            >
              <template v-if="allowAddNewOption">
                <span @click="addNewOption" class="cursor-pointer">
                  {{ addNewOptionMessage() }}
                </span>
              </template>
              <template v-else>No results</template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "click-outside-vue3"
import { createPopper } from "@popperjs/core"

export default {
  props: {
    id: { type: String },
    options: { type: Array, default: () => [] },
    selectName: { type: String },
    placeholder: { type: String },
    autoSelect: { type: Boolean, default: false },
    autoVisit: { type: Boolean, default: false },
    ajaxUrl: { type: String },
    initialSelected: {
      default: () => {
        return {}
      },
    },
    multipleSelect: { type: Boolean, default: false },
    errors: { type: Array },
    allowAddNewOption: { type: Boolean, default: false },
    showClearButton: { type: Boolean, default: true },
    bigDropdown: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      mutatedOptions: this.options,
      searching: false,
      fetchingData: false,
      selectedOption: null,
      selectedMultipleOptions: null,
      searchValue: "",
      highlightedIndex: 0,
      doneFirstEmit: false,
      blankOptionSelected: false,
      popper: null,
      visibleGroups: [],
    }
  },
  computed: {
    computedPlaceholder() {
      if (this.placeholder) return this.placeholder
      if (this.ajaxUrl) return "Type to search..."
      return "Select an option..."
    },
    filteredOptions() {
      // Don't filter on client with AJAX - filtering on the server
      if (this.ajaxUrl) {
        return this.mutatedOptions
      } else {
        return this.mutatedOptions.filter(
          (option) =>
            option.label
              .toLowerCase()
              .indexOf(this.searchValue.toLowerCase()) >= 0 ||
            (option.sublabel &&
              option.sublabel
                .toLowerCase()
                .indexOf(this.searchValue.toLowerCase()) >= 0)
        )
      }
    },
    showNoResults() {
      return (
        this.searchValue &&
        !this.fetchingData &&
        this.filteredOptions.length === 0 &&
        !(this.ajaxUrl && this.searchValue.length == 1)
      )
    },
    optionsAreSelected() {
      return (
        this.selectedOption.label || this.selectedMultipleOptions.length > 0
      )
    },
    singleOptionSelected() {
      return this.selectedOption.label
    },
    showErrors() {
      return this.errors && this.errors.length > 0
    },
  },
  methods: {
    close() {
      this.searching = false
      this.searchValue = ""
      this.highlightedIndex = 0

      if (this.ajaxUrl) {
        this.mutatedOptions = []
      }
    },
    handleClick(option) {
      if (this.disabled) return
      this.selectOrDeselectOption(option)

      if (this.multipleSelect) {
        // Re-position the search dropdown
        this.initPopper()
      } else {
        // Close if only selecting one
        this.focusButton()
        this.close()
      }

      // Visit a new page with change only in the query param,
      // ex: "contract_id=xyz"
      if (this.autoSelect) {
        let params = qs.parse(location.search, { ignoreQueryPrefix: true })
        params[this.selectName] = this.selectedOption.value
        Turbo.visit(
          qs.stringify(params, {
            addQueryPrefix: true,
            arrayFormat: "brackets",
          })
        )
      }

      // Visit a new page. Select value is a full url,
      // ex: "/beamforce/contracts/xyz"
      if (this.autoVisit) {
        Turbo.visit(this.selectedOption.value)
      }
    },
    startSearch() {
      if (this.disabled) return
      this.searching = true
      this.focusInput()
    },
    addNewOptionMessage() {
      let option = this.selectedMultipleOptions.find(
        (el) => el.label === this.searchValue
      )

      if (option) {
        return 'Press enter to remove "' + option.label + '"'
      } else {
        return 'Press enter to add "' + this.searchValue + '"'
      }
    },
    selectHighlighted() {
      let option = this.filteredOptions[this.highlightedIndex]

      if (option) {
        this.handleClick(option)
      } else if (this.allowAddNewOption) {
        this.addNewOption()
      }
    },
    scrollToHighlighted() {
      this.$refs.optionsul.children[this.highlightedIndex].scrollIntoView({
        block: "nearest",
      })
    },
    scrollToSelected() {
      if (this.singleOptionSelected) {
        let index = this.filteredOptions.indexOf(this.selectedOption)

        // When a new option is added to the select via the Ui, it will not be in filtertedOptions
        // so index will be -1, which we can't highlight or scroll to.
        if (index >= 0) {
          this.highlight(index)
        }
      }
    },
    focusInput() {
      if (this.$refs.input) this.$refs.input.focus()
    },
    focusButton() {
      if (this.$refs.buttonstart) this.$refs.buttonstart.focus()
    },
    highlight(index) {
      this.highlightedIndex = index

      this.scrollToHighlighted()
    },
    highlightPrev() {
      let index = this.highlightedIndex - 1

      if (index < 0) {
        index = this.filteredOptions.length - 1
      }

      this.highlight(index)
    },
    highlightNext() {
      let index = this.highlightedIndex + 1

      if (index > this.filteredOptions.length - 1) {
        index = 0
      }

      this.highlight(index)
    },
    handleInput() {
      this.highlightedIndex = 0

      if (this.ajaxUrl) {
        if (this.searchValue.length >= 1) {
          this.fetchingData = true
          let appendChar = this.ajaxUrl.indexOf("?") > 0 ? "&" : "?"

          this.axios
            .get(
              `${this.ajaxUrl}${appendChar}q=${encodeURIComponent(
                this.searchValue
              )}`
            )
            .then((response) => {
              this.mutatedOptions = response.data.data
              this.fetchingData = false
            })
        } else {
          this.mutatedOptions = []
        }
      }
    },
    clearAndClose() {
      this.selectedOption = {}
      this.selectedMultipleOptions = []
      this.close()
    },
    isOptionSelected(option) {
      if (this.multipleSelect) {
        return this.selectedMultipleOptions.some(
          (el) => el.value == option.value
        )
      } else {
        return this.selectedOption.value == option.value
      }
    },
    deselect(option) {
      this.selectedMultipleOptions = this.selectedMultipleOptions.filter(
        (el) => el.value !== option.value
      )

      // Post [''] to server instead of [] for an empty array of selectedMultipleOptions.
      // This is necessary because Rails ignores totally empty arrays as a security measure,
      // and therefore there is no way to save an empty array.
      // We handle [''] on the backend in ApplicationController#handle_empty_arrays.
      // More info here https://github.com/rails/rails/issues/13420
      if (this.selectedMultipleOptions.length == 0) {
        this.blankOptionSelected = true
      } else {
        this.blankOptionSelected = false
      }
    },
    addNewOption() {
      let newOption = { label: this.searchValue, value: this.searchValue }
      this.handleClick(newOption)
      this.searchValue = ""
    },
    initPopper() {
      if (this.searching) {
        this.popper = createPopper(this.$el, this.$refs.popper, {
          placement: "bottom-start",
          strategy: "fixed",
          modifiers: [
            {
              name: "sameWidth",
              enabled: true,
              fn: ({ state }) => {
                state.styles.popper.width = `${state.rects.reference.width}px`
              },
              phase: "beforeWrite",
              requires: ["computeStyles"],
            },
          ],
        })

        this.$nextTick(() => this.scrollToSelected())
      } else {
        this.popper = null
      }
    },
    selectOrDeselectOption(option) {
      if (!this.isOptionSelected(option)) {
        if (this.multipleSelect) {
          this.selectedMultipleOptions.push(option)
          this.blankOptionSelected = false
        } else {
          this.selectedOption = option
        }
      } else if (this.multipleSelect) {
        this.deselect(option)
      } else {
        this.clearAndClose()
      }
    },
  },
  updated() {
    if (this.searching) {
      this.focusInput()
    }
  },
  created() {
    // If options is an array of strings, map into an object
    if (this.mutatedOptions && typeof this.mutatedOptions[0] !== "object") {
      this.mutatedOptions = this.mutatedOptions.map((el) => {
        return {
          label: el,
          value: el,
        }
      })
    }

    if (this.initialSelected) {
      if (Array.isArray(this.initialSelected)) {
        if (typeof this.initialSelected[0] === "object") {
          this.selectedMultipleOptions = this.initialSelected
        } else {
          this.selectedMultipleOptions = this.initialSelected.map((el) => {
            return { label: el, value: el }
          })
        }
      } else if (typeof this.initialSelected === "object") {
        // Initial selected is a full object
        this.selectedOption = this.initialSelected
      } else {
        // Initial selected is a value, select the object
        const option = this.mutatedOptions.find((el) => {
          return el.value == this.initialSelected
        })

        if (option) this.selectedOption = option
      }
    }

    // Do this instead of using a default to trigger doneFirstEmit when no default option
    if (!this.selectedMultipleOptions) this.selectedMultipleOptions = []
    if (!this.selectedOption) this.selectedOption = {}
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  watch: {
    selectedOption(newOption) {
      if (this.multipleSelect) return
      // Don't emit on initialization - otherwise the AutoSaveField saves
      // straight away
      if (!this.doneFirstEmit) {
        this.doneFirstEmit = true
        return
      }
      this.$emit("update:modelValue", newOption.value)
    },
    selectedMultipleOptions: {
      handler(newOptions) {
        if (!this.multipleSelect) return

        // Don't emit on initialization - otherwise the AutoSaveField saves
        // straight away
        if (!this.doneFirstEmit) {
          this.doneFirstEmit = true
          return
        }

        this.$emit(
          "update:modelValue",
          newOptions.map((el) => el.value)
        )
      },
      deep: true,
    },
    searching() {
      this.initPopper()
    },
  },
}
</script>
