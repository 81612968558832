<template>
  <div class="w-full">
    <div :class="{ '-mt-8': showTabs }">
      <slot name="title"></slot>
      <div v-if="showTabs" class="mb-6 flex justify-end">
        <select
          @change="fetchAndRender()"
          v-model="selectedGroup"
          class="minimal-select form-select"
        >
          <option v-for="tab in tabs" :key="`option-${tab}`">
            {{ tab }}
          </option>
        </select>
      </div>
    </div>
    <div
      v-if="hasFiltersSlot"
      class="mt-2 flex items-center justify-end bg-white"
    >
      <slot name="filters"></slot>
    </div>
    <div class="h-full">
      <canvas :id="canvasId"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto"
import CurrencyMixin from "./mixins/CurrencyMixin"
import { chartColors } from "./charts/chart-colors"

import ChartDataLabels from "chartjs-plugin-datalabels"

export default {
  mixins: [CurrencyMixin],
  props: {
    url: { type: String },
    incomingData: { type: Object, default: null },
    currencyChart: { type: Boolean, default: false },
    chartType: { type: String, default: "line" },
    showTabs: { type: Boolean, default: true },
    xAxisLabel: { type: String, default: null },
    initialSelectedGroup: { type: String, default: "Monthly" },
    displayLegend: { type: Boolean, default: false },
    colours: {
      type: Array,
      default: chartColors,
    },
  },
  data() {
    return {
      chart: null,
      chartData: null,
      tabs: ["Weekly", "Monthly", "Quarterly", "Yearly"],
      selectedGroup: this.initialSelectedGroup,
    }
  },
  computed: {
    hasFiltersSlot() {
      return !!this.$slots.filters
    },
    stackedBarChart() {
      return this.chartType == "stacked-bar"
    },
    actualChartType() {
      if (this.stackedBarChart) {
        return "bar"
      }

      return this.chartType
    },
    canvasId() {
      return Math.random().toString(36).substring(7)
    },
    dataUrl() {
      if (!this.url) return
      let appendChar = this.url.indexOf("?") > 0 ? "&" : "?"

      return `${this.url}${appendChar}group=${this.selectedGroup.toLowerCase()}`
    },

    options() {
      const options = {
        responsive: true,
        plugins: {
          legend: {
            display: this.displayLegend,
          },

          tooltip: {
            callbacks: {
              label: (context) => {
                const datasetLabel = context.dataset.label
                const yLabel =
                  typeof context.parsed === "object"
                    ? context.parsed.y
                    : context.parsed
                let dataPoint = null

                if (this.currencyChart) {
                  dataPoint = this.formatCurrency(yLabel)
                } else {
                  dataPoint = yLabel.toLocaleString()
                }

                if (datasetLabel) {
                  return `${datasetLabel}: ${dataPoint}`
                } else {
                  return dataPoint
                }
              },
            },
          },

          // the datalabels plugins allows to display
          // additional labels
          datalabels: {
            labels: {
              label1: {
                align: "end",
                anchor: "end",
                font: { weight: "bold" },
                offset: 23,
                formatter: function (value, context) {
                  if (!context.dataset.label1) return null
                  return context.dataset.label1[context.dataIndex]
                },
              },
              label2: {
                align: "end",
                anchor: "end",
                offset: 5,
                formatter: function (value, context) {
                  if (!context.dataset.label2) return null
                  return context.dataset.label2[context.dataIndex]
                },
              },
            },
          },
        },

        scales: {
          x: {
            stacked: this.stackedBarChart,
            grid: {
              display: false,
            },
            display: (scale) => {
              return scale.chart.config.type !== "pie"
            },
            title: {
              display: this.xAxisLabel,
              text: this.xAxisLabel,
            },
          },

          y: {
            stacked: this.stackedBarChart,
            beginAtZero: true,
            display: (scale) => {
              return scale.chart.config.type !== "pie"
            },
            ticks: {
              callback: (value) => {
                return this.currencyChart
                  ? this.formatCurrency(value)
                  : value.toLocaleString()
              },
            },
          },
        },
      }

      return options
    },
  },
  watch: {
    url() {
      this.fetchAndRender()
    },

    incomingData() {
      this.fetchAndRender()
    },
  },
  methods: {
    destroy() {
      if (this.chart) {
        this.chart.destroy()
      }
    },

    renderChart() {
      const ctx = document.getElementById(this.canvasId).getContext("2d")
      this.destroy()
      this.chart = new Chart(ctx, {
        type: this.actualChartType,
        data: this.chartData,
        options: this.options,
        plugins: [
          ChartDataLabels,
          {
            // https://stackoverflow.com/a/67723827
            beforeInit(chart) {
              // Get a reference to the original fit function
              const originalFit = chart.legend.fit

              // Override the fit function
              chart.legend.fit = function fit() {
                // Call the original function and bind scope in order to use `this` correctly inside it
                originalFit.bind(chart.legend)()
                // Change the height as suggested in other answers
                this.height += 30
              }
            },
          },
        ],
      })
    },

    async fetchAndRender() {
      let data = null

      if (this.dataUrl) {
        const response = await this.axios.get(this.dataUrl)
        data = response.data
      } else {
        data = this.incomingData
      }

      if (!data) return

      const datasets = data.chart_data.map((value, index) => {
        return {
          backgroundColor: this.colours[index],
          borderColor: this.colours[index],
          pointBackgroundColor: this.colours[index],
          fill: false,
          ...value,
        }
      })

      this.chartData = {
        labels: data.chart_labels,
        datasets: datasets,
      }

      this.renderChart()
    },
  },
  mounted() {
    this.fetchAndRender()
  },
}
</script>
